import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from 'react-router-dom';

import {
  Alignment,
  Button,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  Classes,
} from "@blueprintjs/core";

import MarketClock from './components/MarketClock';
import GenerationChartContainer from './components/GenerationChartContainer';
import SystemLoadChartContainer from './components/SystemLoadChartContainer';
import GenerationOffersContainer from './components/GenerationOffersContainer';
import OfferStackContainer from './components/OfferStackContainer';
import WindForecastChartContainer from './components/WindForecastChartContainer';
import AsxPortfolioMarginContainer from './components/AsxPortfolioMarginContainer';
import WindDemandBalanceContainer from './components/WindDemandBalanceContainer';
import NdsiChartContainer from './components/NdsiChartContainer';

import { Amplify, Auth } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';
import MMReviewContainer from "./components/MMReviewContainer";


Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: 'SparcAPI',
        endpoint: process.env.REACT_APP_SPARC_API_ENDPOINT,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        }
      }
    ]
  }
});


const COGNITO_RESTRICTED_GROUP = 'restricted';


function App({signOut, user}) {
  return (
    <BrowserRouter>
      <div>
        <Home signOut={signOut} user={user}/>
        <Routes>
          <Route path="/generation-charts" element={<GenerationChartContainer apiUrl="/ems/generation/chart_grouping"/>} />
          <Route path="/10min-generation-charts" element={<GenerationChartContainer apiUrl="/ems/10min_generation/chart_grouping"/>} />
          <Route path="/load-charts" element={<SystemLoadChartContainer />} />
          <Route path="/generation-offers" element={<GenerationOffersContainer />} />
          <Route path="/offer-stacks" element={<OfferStackContainer />} />
          <Route path="/wind-forecast-charts" element={<WindForecastChartContainer />} />
          <Route path="/asx-portfolio-margin" element={<AsxPortfolioMarginContainer />} />
          <Route path="/wind-demand-balance" element={<WindDemandBalanceContainer />} />
          <Route path="/ndsi-charts" element={<NdsiChartContainer />} />
          <Route path="/session-review" element={<MMReviewContainer />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}


function Home({ signOut, user }) {
  let groups = user.signInUserSession.accessToken.payload["cognito:groups"];
  if (groups === undefined) {
    groups = [];
  }

  return (
    <div className="App">
      <Navbar>

        <NavbarGroup align={Alignment.LEFT}>
          <NavbarHeading>Sparc</NavbarHeading>
          <NavbarDivider />
        </NavbarGroup>

        <NavbarGroup align={Alignment.LEFT}>

          <Link to="/generation-charts">
            <Button
              className={Classes.MINIMAL}
              icon="rig"
              text="Generation"
            />
          </Link>

          <Link to="/10min-generation-charts">
            <Button
              className={Classes.MINIMAL}
              icon="rig"
              text="10min Generation"
            />
          </Link>

          <Link to="/generation-offers">
            <Button
              className={Classes.MINIMAL}
              icon="full-stacked-chart"
              text="Historic Offers"
            />
          </Link>

          <Link to="/offer-stacks">
            <Button
              className={Classes.MINIMAL}
              icon="full-stacked-chart"
              text="Offer Stacks"
            />
          </Link>

          <Link to="/load-charts">
            <Button
              className={Classes.MINIMAL}
              icon="timeline-line-chart"
              text="Demand"
            />
          </Link>


          <Link to="/wind-forecast-charts">
            <Button
              className={Classes.MINIMAL}
              icon="timeline-line-chart"
              text="Wind Forecasts"
            />
          </Link>

          <Link to="/wind-demand-balance">
            <Button
              className={Classes.MINIMAL}
              icon="timeline-line-chart"
              text="DNoW"
            />
          </Link>

          <Link to="/ndsi-charts">
            <Button
              className={Classes.MINIMAL}
              icon="timeline-line-chart"
              text="NDSI"
            />
          </Link>

          <Link to="/session-review">
            <Button
              className={Classes.MINIMAL}
              icon="timeline-line-chart"
              text="MM Session"
            />
          </Link>

          <Link to="/asx-portfolio-margin">
            <Button
              className={Classes.MINIMAL}
              icon="grid"
              text="Risk"
            />
          </Link>

        </NavbarGroup>

        <NavbarGroup align={Alignment.RIGHT}>
          <Button onClick={signOut}>Sign out</Button>
        </NavbarGroup>

        <NavbarGroup align={Alignment.RIGHT}>
          <MarketClock />
        </NavbarGroup>

      </Navbar>
    </div>
  );
}

export default withAuthenticator(App);
