import React, { useEffect, useState } from 'react';
import { Cell, Column, Table2 as Table, RenderMode } from '@blueprintjs/table';
import './MMReviewTable.css'

function SummaryTable({ tableData }) {
    const [columnWidths, setColumnWidths] = useState([]);
    const [columns, setColumns] = useState([]);
    const [numRows, setNumRows] = useState([]);

    useEffect(() => {
        // Initialize or update column names from tableData
        if (!tableData || tableData.length === 0) {
            setColumnWidths([]);
            setColumns([]);
            setNumRows([]);
            return;
        }

        setNumRows(tableData.length);

        const rowShadingDelta = 0.15;
        const dataColumnNames = Object.keys(tableData[0]);
        const columnNames = dataColumnNames.filter(name => !['last_aggressor', 'emh_agg'].includes(name));
        const columnLabs = columnNames.map((name) => name
                    .split('_') // Split by underscores
                    .map(word => word!='emh'? word.charAt(0).toUpperCase() + word.slice(1) : word) // Capitalize each word
                    .join(' ') // Join words with a space
                );

        // Define column widths
        const wideCols = ['record_timestamp', 'exchange_timestamp']
        const defaultColumnWidth = 80; // Default width for each column

        const initialColumnWidths = columnNames.map((n) =>
                wideCols.includes(n) ? 110 : defaultColumnWidth
        );
        setColumnWidths(initialColumnWidths);

        // Generate color coding based on timestamp differences
        const timestamps = tableData.map(row => parseTimeString(row['record_timestamp']));
        let colorCodes = [true]; // Initialize with the first color

        for (let i = 1; i < timestamps.length; i++) {
            const timeDiff = ((timestamps[i] - timestamps[i - 1]));
            if (timeDiff < rowShadingDelta) {
                colorCodes.push(colorCodes[i - 1]); // Same color for close timestamps
            } else {
                colorCodes.push(!colorCodes[i - 1]); // Alternate color for distant timestamps
            }
        }

        console.log(tableData.map(row => row['record_timestamp']));
        console.log(timestamps);
        console.log(colorCodes);


        const cellRenderer = (rowIndex, colIndex) => {
            const rowData = tableData[rowIndex];
            if (!rowData) return <Cell>Error</Cell>; // Or any placeholder to indicate missing tableData

            const classes = ['cell-center-align']

            // apply borders
            const borderCols = ['record_timestamp','ask_qty','volume','ask_wk_qty','emh_volume']
            if (borderCols.includes(columnNames[colIndex])) {
                classes.push('border-column');
            }

            // shading based on time difference
            if (colorCodes[rowIndex]) {
                classes.push('white-row');
            } else {
                classes.push('grey-row');
            }


            const currentColumnName = columnNames[colIndex];
            
            // get the prior row for this contract
            const currentContractCode = rowData['contract_code'];
            const previousRows = tableData.slice(0, rowIndex).filter((row) => row['contract_code'] === currentContractCode);
            const priorRowData = previousRows.length > 0 ? previousRows[previousRows.length - 1] : null;
            // check each column to see if there is a value change from priorRow (be defensive against priorRow being null)
            if (priorRowData) {
                const priorValue = priorRowData[currentColumnName];
                const currentValue = rowData[currentColumnName];

                    if (currentValue !== priorValue) {
                        if (currentColumnName !== 'record_timestamp') {
                            classes.push('cell-value-change');
                        }
                        // check price cols to see if up or down
                        if (currentColumnName.includes('price') & currentValue != null) {
                            if (rowData[currentColumnName] > priorRowData[currentColumnName]) {
                                classes.push('price-change-up');
                            } else if (rowData[currentColumnName] < priorRowData[currentColumnName]) {
                                classes.push('price-change-down');
                            }
                        }
                    } else {
                        if (currentColumnName === 'exchange_timestamp') {
                            classes.push('stale-exchange-timestamp');
                        }
                    }
            }
            // TODO this isn't working
            // check last qty for aggressor
            if (currentColumnName==='last_qty') {
                const aggressorValue = rowData['last_aggressor'];
                if (aggressorValue === 'BUY') {
                    classes.push('last_qty_buy');
                } else if (aggressorValue === 'SELL') {
                    classes.push('last_qty_sell');
                }
            }
            // check if the emh last was aggressed by us
            if (currentColumnName==='emh_last_qty') {
                const aggressorValue = rowData['emh_agg'];
                if (aggressorValue) {
                    classes.push('emh-aggressor');
                }
            }

            const cellValue = rowData[currentColumnName];
            return <Cell className={classes.join(' ')}>{cellValue !== undefined ? cellValue : '-'}</Cell>;
        };

        // Create columns
        const newColumns = columnNames.map((columnName, index) => ({
            key: index,
            name: <span className="header-text-wrap">{columnLabs[index]}</span>,
            cellRenderer: (rowIndex) => cellRenderer(rowIndex, index)
        }));

        setColumns(newColumns);

    }, [tableData]); // Re-run when `tableData` changes


    if (!tableData || tableData.length === 0 || columns.length === 0) {
        return <div>No tableData available.</div>;
    }

    return (

        <Table
            numRows={numRows}
            renderMode={RenderMode.NONE}
            columnWidths={columnWidths}
        >
            {columns.map(({ key, name, cellRenderer }) => (
                <Column key={key} name={name} cellRenderer={cellRenderer} />
            ))}
        </Table>
    );
}
const parseTimeString = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':');

    const totalSeconds = (parseInt(hours) * 3600) + (parseInt(minutes) * 60) + parseFloat(seconds);

    console.log(`${timeString} = ${totalSeconds}`);
    return totalSeconds;
};

export default SummaryTable;


// Todo:
// freeze cont code and record timestamp cols
// extend vertical border to column headers
// select all/ clear all contract filter
// add another table that has tns. scroll it so the centre of the viewport is aligned as you scroll the main (and vice versa)
// filter tns button (filters that second table to selected conts)