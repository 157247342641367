import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { API } from 'aws-amplify';
import {HTMLSelect, MenuItem} from '@blueprintjs/core';
import {MultiSelect2} from "@blueprintjs/select";
import { DateInput2 } from "@blueprintjs/datetime2";
import SummaryTable from './MMReviewTable';
import styles from './MMReviewContainer.module.css';

function SessionReviewContainer() {
  document.title = `SessionReview`;
  const [tradeDates, setTradeDates] = useState([]);
  const [data, setData] = useState(null);
  const [selectedTradeDate, setSelectedTradeDate] = useState(
      tradeDates.length > 0 ? tradeDates[tradeDates.length - 1] : '');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [isFetchingTradeDates, setIsFetchingTradeDates] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [filterTape, setFilterTape] = useState(true);

  function loadingMsg() {
    let msg = 'No Data';
    if (isFetchingTradeDates) {
      msg = 'Fetching Trade Dates';
    } else {
      if (isFetchingData) {
        msg = 'Fetching Data';
      }
    }
    return <p>{msg}</p>;
  }

  async function fetchTradeDates() {
    // Call data file for testing
    // const response = await fetch('/test/trade_dates.json').then(res => res.json());
    const response = await API.get('SparcAPI', `/mm/session_dates`, {});

    //console.log(`dates_response ${JSON.stringify(response, null, 2)}`);
    setTradeDates(response.trade_date); // Update state with fetched data
    setSelectedTradeDate(response.trade_date[response.trade_date.length -1]);
  }

  async function fetchData() {
    // Call data file for testing
    // const response = await fetch(`/test/${selectedTradeDate}-data.json`).then(res => res.json());
    const response = await API.get('SparcAPI', `/mm/session_review`, {
      queryStringParameters: {
        trade_date: selectedTradeDate
      }
    });

    //console.log(`data_response ${JSON.stringify(response, null, 2)}`);
    setData(response);
  }

  // Fetch trade dates when the component mounts
  useEffect(() => {
    setIsFetchingTradeDates(true);
    fetchTradeDates().finally(() => setIsFetchingTradeDates(false)); // Call async function inside useEffect
  }, []); // Empty dependency array ensures this runs once on mount

  // Fetch data when tradeDate changes
  useEffect(() => {
    if (!selectedTradeDate || selectedTradeDate === '') return;
    setIsFetchingData(true);
    fetchData().finally(() => setIsFetchingData(false));
  }, [selectedTradeDate]);

  useEffect(() => {
    if (!data) return;
    // try and keep the selected user, but get the first new one if old isn't there anymore
    const newUsers = data.users;
    const newContracts = data.contracts;

    setUsers(newUsers);
    setContracts(newContracts);

    if (!newUsers.includes(selectedUser)) {
      if(newUsers.length > 0){
        setSelectedUser(newUsers[0]);
      } else {
        console.error(`No Users found for ${selectedTradeDate}. This indicates there is no data.`)
      }
    }

    const validSelectedContracts = selectedContracts.filter(c => newContracts.includes(c));
    setSelectedContracts(validSelectedContracts);

    if (selectedContracts === []) {
      if (newContracts > 0) {
        setSelectedContracts([newContracts[0]]);
      } else {
        console.error(`No contracts found for ${selectedTradeDate}. This indicates there is no data.`)
      }
    }

    }, [data])

  const handleDateChange = (newDate) => setSelectedTradeDate(newDate);
  const handleUserChange = (event) => setSelectedUser(event.target.value);
  const handleContractChange = (contract) => {
    setSelectedContracts((prevSelectedContracts) =>
        prevSelectedContracts.includes(contract)
            ? prevSelectedContracts.filter((c) => c !== contract) // Remove if selected
            : [...prevSelectedContracts, contract] // Add if not selected
    );
  };
  const jsDateFormatter = {
    formatDate: date => DateTime.fromJSDate(date).toISODate(),
    parseDate: str => DateTime.fromISO(str),
    placeholder: 'YYYY-MM-DD'
  };

  const validDates = new Set(tradeDates);
  const dayPickerProps = {
    disabledDays: (date) => !validDates.has(DateTime.fromJSDate(date).toISODate())
  };

  // Extracting user data safely and recalculating each render
  const userData = data?.[selectedUser];
  const userSummary = userData?.summary ?? []; // Default to empty array if undefined

  // Filtering records based on selectedContracts
  const filteredSummary = userSummary.filter((record) =>
      selectedContracts.length === 0 || selectedContracts.includes(record.contract_code)
    ).map(({ ttuser, ...rest }) => rest);

  return (
      <div>
        <div className={styles.flex_container}>
          <div className={styles.flex_child}>
            <p>Trading Date</p>
            <DateInput2
                {...jsDateFormatter}
                dayPickerProps={dayPickerProps}
                value={selectedTradeDate}
                onChange={handleDateChange}
            />
          </div>
          <div className={styles.flex_child}>
            <p>Contracts</p>
            <MultiSelect2
                items={contracts} // List of available contracts from API
                selectedItems={selectedContracts} // Currently selected contracts
                onItemSelect={handleContractChange} // Add/remove contract on selection
                itemRenderer={(contract, {handleClick}) => (
                    <MenuItem
                        key={contract}
                        text={contract}
                        onClick={handleClick}
                        active={selectedContracts.includes(contract)}
                    />
                )}
                tagRenderer={(contract) => contract} // Display selected contracts as tags
                tagInputProps={{
                  onRemove: (tag, index) => {
                    setSelectedContracts((prevSelectedContracts) =>
                        prevSelectedContracts.filter((_, i) => i !== index)
                    );
                  },
                }}
                placeholder="Select contracts..."
                resetOnSelect // Clears the search box after selection
            />
          </div>
          <div className={styles.flex_child}>
            <p>TT User</p>
            <HTMLSelect options={users} onChange={handleUserChange} value={selectedUser}/>
          </div>
        </div>
        <div style={{height: "2000px", overflow: "scroll"}}>
          {data && !isFetchingData ? (
              <SummaryTable
                  tableData={filteredSummary}
              /> ) :
              loadingMsg()
          }
        </div>
      </div>
  );
}

// TODO tidy up the actual table

export default SessionReviewContainer;
